import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBuilding, faStore } from "@fortawesome/free-solid-svg-icons";
import { HOME_TITLE } from "constants/siteData";
import logo from "static/img/logo.png";
import "./Home.css";

import backgroundImg from "static/img/lightning_background.jpg";

var backgroundStyle = {
  backgroundImage: `linear-gradient(to bottom, rgba(200, 200, 200, 0.62), rgba(0, 0, 0, 0.83)),url(${backgroundImg})`,
  backgroundSize: "cover",
};

const Home = () => {
  useEffect(() => {
    document.title = HOME_TITLE;
  });

  return (
    <>
      <div className="mini-segment is-center " style={backgroundStyle}>
        <div className="padded max-width">
          <img src={logo} />
        </div>
      </div>

      <div className="mini-segment grey-background l-padded ">
        <div className="container">
          <div className="columns container">
            <div className="column is-two-thirds">
              <h1 className="is-primary-color giant-text">
                Your Local Electrician
              </h1>
              <p>
                Certified Electric has been proudly serving throughout California
                for over 25 years. We are
                a privately owned electrical company, licensed and bonded by the
                state of California. We offer a wide variety of electrical
                services at a great price.
              </p>

              <div className="pushed-down large-text">
                Give us a call at {" "}
                <a className="is-primary-color" href="tel:5307180568">
                  (530) 718-0568
                </a>{" "}
              </div>
            </div>
            <div className="column">
              <h2 className="is-primary-color giant-text ">Services Offered</h2>
              <div className="content">
                <ul>
                  <li>
                    <strong className="is-white">
                      24hr Emergency Service Calls
                    </strong>
                  </li>
                  <li>Electrical Panel Upgrade</li>
                  <li>Generator Installation</li>
                  <li>Lighting Installation/Remodelling</li>
                  <li>Wiring Repair</li>
                  <li>Wire Locating</li>
                </ul>

                <div className="has-text-centered">
                  <a
                    href="/about"
                    className="button is-primary-background hvr-grow"
                  >
                    See details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="industry-section container">
        <div className="columns">
          <AreaOfIndustry
            title="Residential"
            icon={faHome}
            href="/residential"
            description="
              For all of your home electrical needs, Certified Electric has you covered.  
              From installation to maintenance, we've got the experience.
            "
          />
          <AreaOfIndustry
            title="Commercial"
            icon={faStore}
            href="/commercial"
            description="
            Whether it's handling an electrical emergency or adding on to your existing electrical system, trust Certified Electric to keep your business running.
            "
          />
          <AreaOfIndustry
            title="Industrial"
            href="/industrial"
            icon={faBuilding}
            description="No job is too big for Certified Electric, we have the resources and experience to tackle large industrial electrical work."
          />
        </div>
      </div>
    </>
  );
};

const AreaOfIndustry = (props) => {
  return (
    <div className="column area-of-industry">
      <h2 className="area-title is-primary-color">
        <FontAwesomeIcon icon={props.icon} /> {props.title}
      </h2>
      <div className="area-description">
        <p>{props.description}</p>
      </div>
      <div className="has-text-centered hvr-grow">
        <a
          href={props.href}
          className="button is-centered is-primary-background"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default Home;
